import React, { Component } from 'react';
import Layout from '../components/layout';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { Helmet } from 'react-helmet';


const GenericPage = (props) => {
  let PageData = props.PageData;
  return (
    <Layout>
        <Helmet>
          <title>{PageData.title}</title>
      </Helmet>
        <section id="page-title" className="page-title-parallax page-title-dark" style={{backgroundImage: `url('${PageData.image}')`, padding: '120px 0'}} data-bottom-top="background-position:0px 300px;" data-top-bottom="background-position:0px -300px;">
            <div className="container clearfix page-title">
                <h1>{PageData.title}</h1>
                <span>{PageData.quote}</span>
            </div>
        </section>
        <section id="content">
            <div className="content-wrap">
                <div className="container clearfix">
                    <div className="row col-mb-50">
                        <div className="col-12 markdown-content">
                            <ReactMarkdown plugins={[gfm]} children={PageData.body} />
                        </div>
                    </div>
                </div>
            </div>    
        </section>          
    </Layout>
  )
}

export default GenericPage