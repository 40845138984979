import React, { Component } from 'react';
import GenericPage from '../components/genericpage';
import TotmHistoryData from '../data/totmhistory.json'


const TOTMHistory = () => {
  return (
    <GenericPage PageData = {TotmHistoryData} />
  )
}

export default TOTMHistory